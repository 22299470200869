import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Learning Bitwig Studio`}</h1>
    <p>{`Below are some resources for learning Bitwig Studio.`}</p>
    <h2>{`Documentation`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dash.bitwig.com/documentation/Bitwig%20Studio%20User%20Guide%20English.pdf"
        }}>{`Bitwig Studio manual`}</a></li>
    </ul>
    <h2>{`Courses - payed`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ask.video/library/application/bitwigstudio?afid=bUM6RfN2U6"
        }}>{`Ask.Video`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.sonicacademy.com/courses/introduction-to-bitwig-studio"
        }}>{`Sonic Academy`}</a></li>
    </ul>
    <h2>{`Tutorials`}</h2>
    <h3>{`Youtube`}</h3>
    <p>{`Some useful Youtube accounts to check out:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/user/bitwig"
        }}>{`Bitwig`}</a>{` - official Bitwig Studio Youtube Channel`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCuzDmHD4WeS4dwhFXPgm7GA"
        }}>{`Baphometrix`}</a>{` - mostly in depth and longer tutorials`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCPI1x2iyASeNaeRYVSGXTqA"
        }}>{`Mattias Holmgren`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/user/polaritydnb"
        }}>{`Polarity Music`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=nbEeaV0TgTI&list=PLn_168CDEmDfrzQwHI6_LSvXfdyGC2kLm"
        }}>{`TÂCHES`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCMS0X8oznir77wXrM5Luhow"
        }}>{`Alckemy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCMLl5O-kS7iEMJgMrsZayMw"
        }}>{`Audio Digital`}</a>{` - Sound Design Tutorials`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCaIG6vStP7xh313mPzJClbA"
        }}>{`Chill Computer Guy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/user/BrainLaxativeMusic"
        }}>{`Garron`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      